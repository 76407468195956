@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashboard-sidebar-container {
  background-color: #fcfcfc;
  height: 100%;
  width: 253px;
  padding: 24px 24px;
  min-width: 253px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @include screen(mobile) {
    display: none;
  }

  @include screen(tablet) {
    width: 80px;
    padding: 24px 16px;
    min-width: 80px;
  }
  @include screen(small-desktop) {
    width: 81px;
    padding: 24px 16.5px;
    min-width: 81px;
  }

  @include screen(big-screen) {
    width: 301px;
    min-width: 301px;
  }

  .sidebar-logo-container {
    img {
      //width: 48px;
      height: 48px;
      margin: unset;
    }
  }
}

.sidebar-content-container {
  flex: 1;
  margin-top: 48px;

  //.sidebar-item-menu-container,
  //.sidebar-item-list-container {
  //  @include screen(mobile) {
  //    display: none;
  //  }
  //
  //  @include screen(tablet) {
  //    display: none;
  //  }
  //  @include screen(small-desktop) {
  //    display: none;
  //  }
  //}

  .sidebar-section-title {
    @include screen(mobile) {
      display: none;
    }

    @include screen(tablet) {
      display: none;
    }
    @include screen(small-desktop) {
      display: none;
    }
  }
}

.sidebar-footer-container {
  @include screen(mobile) {
    display: none;
  }

  @include screen(tablet) {
    display: none;
  }
  @include screen(small-desktop) {
    display: none;
  }
}

.sidebar-item-container {
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 0px 12px;
  border-radius: 12px;
  cursor: pointer;
  color: #6f767e;
  //   box-shadow: inset 0 1px 1px 0 #fff, inset 0 -2px 1px 0 rgb(0 0 0 / 5%);
  background-color: transparent;
  position: relative;

  svg {
    min-width: 20px;
  }

  &:hover {
    color: #1a1d1f;
    svg {
      path {
        fill: #1a1d1f !important;
      }
    }
  }

  .task-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
  }

  .sidebar-item-title-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: 14px;
    margin-left: 12px;
    flex: 1 1 auto;

    font-weight: 600;

    @include screen(mobile) {
      display: none;
    }

    @include screen(tablet) {
      display: none;
    }
    @include screen(small-desktop) {
      display: none;
    }
  }
}

.sidebar-item-container.selected {
  box-shadow: inset 0 1px 1px 0 #fff, inset 0 -2px 1px 0 rgb(0 0 0 / 5%);
  background-color: #efefef;
  color: #1a1d1f;
  svg {
    path {
      fill: #1a1d1f !important;
    }
  }

  @include screen(mobile) {
    box-shadow: none;
    background-color: transparent;
    svg {
      path {
        fill: #2a85ff !important;
      }
    }
  }

  @include screen(tablet) {
    box-shadow: none;
    background-color: transparent;
    svg {
      path {
        fill: #2a85ff !important;
      }
    }
  }
  @include screen(small-desktop) {
    box-shadow: none;
    background-color: transparent;
    svg {
      path {
        fill: #2a85ff !important;
      }
    }
  }
}

.sidebar-item-menu-container {
  @include screen(mobile) {
    display: none;
  }

  @include screen(tablet) {
    display: none;
  }
  @include screen(small-desktop) {
    display: none;
  }
}

.sidebar-item-list-container {
  margin-left: 23px;
  // border-left: 2px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;

  a:last-child .vertical-line {
    display: none;
  }
}

.sidebar-item-list-item-container,
.sidebar-item-list-item-create {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  text-align: left;
  color: #6f767e;
}

//.sidebar-item-list-item-container .sidebar-action {
//  visibility: hidden;
//}

.sidebar-item-list-item-container:hover > .sidebar-action {
  visibility: visible;
}

.sidebar-item-list-item-container.selected {
  color: #1a1d1f;
  background-color: white;
  .folder-icon {
    color: #6f767e;
  }
}

.sidebar-item-list-item-create {
  color: #2a85ff;
  cursor: pointer;
  user-select: none;
}
