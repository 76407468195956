@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.supervisor-title {
  width: 250px;
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: left;
  color: #1a1d1f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.supervisor-role {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  flex-grow: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.36px;
  text-align: left;
  color: #9a9fa5;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-bullet {
  width: 4px;
  height: 4px;
  flex-grow: 0;
  background-color: #354b76;
  border-radius: 2px;
  margin-left: 5px;
  margin-right: 5px;
}

.checkbox {
  width: 18px;
  height: 18px;
  flex-grow: 0;
  margin: 21px 14px 33px 246px;
  padding: 2.5px 2.5px 1.9px 1.9px;
  border-radius: 13.7px;
  background-color: #ffffff;
}

.isChecked {
  background-color: #2a85ff;
  border-radius: 20px;
  padding: 2.5px 2.5px 1.9px 1.9px;
  width: 18px;
  height: 18px;
  flex-grow: 0;
}

.unChecked {
  width: 18px;
  height: 18px;
  flex-grow: 0;
  padding: 2.5px 2.5px 1.9px 1.9px;
  border-radius: 13.7px;
  border: solid 1.2px #c4c4c4;
  border-radius: 20px;
}
