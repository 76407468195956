.notification-list-container {
  /* height: 100%; */
  min-width: 360px;
  max-width: 360px;
  margin-top: 65px;
  width: 360px;
  /* position: sticky;
  top: 0; */
  overflow: unset;
}

.notification-list-header-container {
  display: flex;
  align-items: center;
  margin: 0px 0px 4px;
  justify-content: space-between;
  margin-bottom: 9px;
}

.notification-header-text {
  font-size: 20px;
  font-weight: bold;
}

.notification-header-mark-read-container {
  font-size: 12px;
  cursor: pointer;
  color: #999;
  font-weight: 600;
}

.notification-header-mark-read-container:hover {
  text-decoration: underline;
}

.notification-item-container {
  display: flex;
  cursor: pointer;
  color: #333b4f;
  background: #fff;
  padding: 19px 18px 19px 20px;
  background-color: #fff;
  margin-bottom: 9px;
  border-radius: 10px;
  font-size: 13px;
}

/* .notification-item-container:hover {
} */

.notification-item-time-container {
  margin-top: 7px;
  color: #999;
  font-size: 12px;
}

.notification-item-icon-container {
  width: 39px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px #70cee3;
  background-color: #c9f1fa;
  min-width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notication-unread-parent-container {
  background-color: rgba(219, 76, 63, 0.4);
  width: 11px;
  height: 11px;
  min-width: 11px;
  border-radius: 11px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.notification-unread-container {
  width: 7px;
  height: 7px;
  background-color: #e04747;
  min-width: 7px;
  border-radius: 10px;
}

.notification-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  flex-direction: column;
  /* margin-top: 150px; */
  border-radius: 10px;
  background-color: #fff;
}

.notification-parent-item-container:hover {
  background-color: #fbf7f0;
}

.notification-timeline-container {
  font-size: 14px;
  font-weight: 600;
  margin-top: 21px;
  margin-bottom: 23px;
  text-transform: capitalize;
}
