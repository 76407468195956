.role-label-container {
  border-radius: 3px;
  height: 25px;
  min-width: 68px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  margin-top: 10px;
}
