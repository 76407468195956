.search-modal {
  width: 642px;
  height: 722px;
}

.tag {
  width: 16px;
  height: 32px;
  flex-grow: 0;
  border-radius: 4px;
  background-color: #b5e4ca;
}

.Search-box {
  width: 590px;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 19px 26px 20px 0;
  padding: 12px 0 12px 8px;
  border-radius: 12px;
  background-color: #f4f4f4;
}

.No-Search-Result {
  height: 32px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.36px;
  text-align: center;
  color: #303030;
}

.imgCss {
  text-align: -webkit-center;
}

.User-Details {
  width: max-content;
  height: 32px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.4px;
  text-align: left;
  color: #1a1d1f;
}

.noResult {
  width: 80%;
  height: 32px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.4px;
  text-align: center;
  color: #1a1d1f;
}

.img-card-user {
  width: 80px;
  height: 80px;
  flex-grow: 0;
  margin: 0 2.3px 6.3px 0;
  border-radius: 6.5px;
  object-fit: cover;
  object-position: 100% 0;
}

.labelCssUser {
  width: max-content;
  flex-grow: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  margin-left: 13px;
  color: #2a85ff;
  text-transform: capitalize;
}

.Recent-search-item-title-user {
  width: max-content;
  height: 47px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.14px;
  overflow: hidden;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  margin-left: 41px;
}

.iconmaillight {
  width: 26px;
  height: 26px;
  flex-grow: 0;
  object-fit: contain;
}

.faheydesignerrobotco {
  width: 208px;
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.16px;
  text-align: left;
  color: #1a1d1f;
}

.Line-2 {
  height: 1px;
  flex-grow: 0;
  background-color: #eee;
}

.headerDiv {
  flex-direction: 'row';
  display: 'flex';
  align-items: 'center';
}

.user-detail-modal-heading {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.user-detail-heading-color-block {
  width: 16px;
  height: 32px;
  flex-grow: 0;
  border-radius: 4px;
  background-color: #b5e4ca;
  margin-right: 10px;
}

.user-details-heading-text {
  width: max-content;
  height: 32px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.4px;
  text-align: left;
  color: #1a1d1f;
}
