@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashboard-container {
  background-color: #f4f4f4;
  display: flex;
  height: 100%;

  .desktop-content-with-header-container {
    flex: 1 1 auto;
    //margin-left: 3px;

    display: flex;
    flex-direction: column;
    width: calc(100% - 253px);

    @include screen(mobile) {
      width: unset;
    }

    @include screen(tablet) {
      // width: 80px;
      // padding: 24px 16px;
      // min-width: 80px;
      width: calc(100% - 80px);
    }
    @include screen(small-desktop) {
      // width: 81px;
      // padding: 24px 16.5px;
      // min-width: 81px;
      width: calc(100% - 81px);
    }

    @include screen(big-screen) {
      // width: 301px;
      // min-width: 301px;
      width: calc(100% - 301px);
    }
    .desktop-content-container {
      padding: 17px 38px;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      // -webkit-transform: translate3d(0, 0, 0);
      // -moz-transform: translate3d(0, 0, 0);
      // transform: translate3d(0, 0, 0);

      @include screen(small-desktop) {
        padding: 19px 21px;
      }
      @include screen(tablet) {
        padding: 19px 21px;
      }
      @include screen(mobile) {
        padding: 14px 19px;
      }
    }
  }
}
