@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.desktop-header-container {
  width: 100%;
  height: 96px;
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  padding: 0 38px;
  justify-content: space-around;

  @include screen(mobile) {
    padding: 0;
    justify-content: space-evenly;
  }

  .header-icon-container {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    position: relative;

    .has-message {
      position: absolute;
      top: 25%;
      right: 25%;

      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ff6a55;
      border: 2px solid #fcfcfc;
    }
  }

  .header-icon-container.selected {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;

    svg {
      path {
        fill: #2a85ff !important;
      }
    }
  }
}

.eventclass-header-profile-pic {
  display: flex;
  // align-items: center;
  .profile-pic {
    min-width: 48px;
    height: 48px;
    min-height: 40px;

    @include screen(mobile) {
      min-width: 40px;
      width: 40px;
      height: 40px;
      min-height: 40px;
    }
    @include screen(tablet) {
      min-width: 40px;
      width: 40px;
      height: 40px;
      min-height: 40px;
    }
  }
}
