.search-modal-container {
  min-width: 772px !important;
}
.ant-modal-body {
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  padding: 0px !important;
  height: 475px;
  display: flex;
  flex-direction: column;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 62%);
  height: 475px;
  /* min-width: 772px; */
}

.search-modal-search-container {
  display: flex;
  align-items: center;
  height: 59.4px;
  border-bottom: 1px solid #ddd;
  padding: 0px 40px;
  min-height: 59.4px;
}

.search-input-container {
  border: none !important;
  font-size: 16px;
}

.search-input-container:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.search-input-container:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.search-modal-type-list-container {
  /* min-width: 53px; */
  height: 25px;
  border-radius: 3px;
  background-color: #ddd;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 15.6px;
  margin-right: 2px;
  padding: 0px 7px;
}

.search-list-footer {
  height: 61.5px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0px 40px;
  color: #999;
}

.search-type-list-container {
  width: 174px;
  border-radius: 6px;
  box-shadow: 0 0 75px 10px rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  border-top: none;
  background-color: #fff;
  padding: 18px 0px;
  text-transform: capitalize;
  height: 185px;
}

.search-type-item {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.search-type-item:hover {
  background-color: #f1f1f1;
}

.search-modal-shortcut-container {
  width: 25px;
  height: 25.9px;
  border-radius: 3px;
  background-color: #ddd;
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
