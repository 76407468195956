.progress-div {
    width: 30%;
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
    .progress-div {
        width: 60%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .progress-div {
        width: 45%;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .progress-div {
        width: 45%;
    }
}