/* TODO:Remove all important tag */
.ant-menu {
  color: #333b4f !important;
  background-color: #ffffff !important;
}
.ant-menu-item:hover {
  color: #000000 !important;
  font-weight: 600 !important;
  background-color: #eeeeee !important;
}
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 0 !important;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu {
  color: #aaaaaa !important;
  font-weight: bold !important;
}
.ant-menu-submenu-selected {
  /* color: #333B4F !important; */
  font-weight: 600 !important;
}
.ant-menu-submenu-title {
  font-weight: bold !important;
}
.ant-menu-submenu-title:hover {
  color: #000000 !important;
  background-color: #eeeeee !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-weight: 500;
}

.ant-menu-item-selected {
  font-weight: 600 !important;
  /* background-color: #FFFFFF; */
}
.handbook-menu {
  padding-inline: 37px;
  height: 40px;
  align-items: center;
  margin-bottom: 8px;
  padding-top: 5px;
  margin-top: 0px;
}
.handbook-menu:hover {
  /* background-color: #eeeeee; */
}
p.handbook-text {
  font-weight: 700;
  color: #333b4f;
  font-size: 14px;
  margin-left: 8px;
}
/* .handbook-text:hover {
  color: #1890ff;
} */

.full-handbook-container svg {
  color: #333b4f;
}

.full-handbook-container:hover svg {
  color: #4d8af0;
}

.full-handbook-container:hover .handbook-text {
  color: #4d8af0;
}

.eventclass-handbook-menu .ant-menu-submenu-title {
  flex-direction: row-reverse;
  padding-left: 37px !important;
}

.eventclass-handbook-menu .ant-menu-submenu-title svg {
  margin-right: 12px;
}

.eventclass-handbook-menu .ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100%);
}

/* .eventclass-handbook-menu .ant-menu-item-selected {
  color: #4d8af0 !important;
  font-weight: 500 !important;
  background-color: #eee !important;
} */
