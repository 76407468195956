.de-textarea {
  background-color: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(154, 159, 165, 0.25);
  border-radius: 12px;

  font-family: 'Inter';

  &:focus-within {
    border-color: rgba(42, 133, 255, 0.35);
  }

  &[aria-invalid='true'] {
    background-color: rgba(255, 188, 153, 0.3);

    border-color: rgba(42, 133, 255, 0.35);
  }

  .ql-toolbar.ql-snow {
    border: unset;
    background-color: #fcfcfc;
    font-family: Inter, sans-serif;

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .ql-stroke {
      stroke: #33383f;
    }
    .ql-fill {
      fill: #33383f;
    }

    button.ql-active {
      color: #2a85ff;

      .ql-stroke {
        stroke: #2a85ff;
      }

      .ql-fill {
        fill: #2a85ff;
      }
    }
  }

  .ql-container.ql-snow {
    font-family: inherit;
    font-size: 14px;
    border: unset;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    color: #33383f;

    .ql-editor {
      min-height: 124px;
    }
  }
}
