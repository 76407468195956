@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.profile-container {
  display: flex;
  @include screen(tablet) {
    flex-direction: column;
  }
  @include screen(mobile) {
    flex-direction: column;
  }
}
.profile-content-container {
  // width: 100%;
  flex: 1 1 auto;

  @include screen(tablet) {
    margin-top: 17px;
  }
  @include screen(mobile) {
    margin-top: 28px;
  }
}
.focused-input {
  color: #272b30;
  font-weight: 600;
  background-color: #9a9fa5;
  border-width: 0px;
  font-size: 15px;
  border-radius: 12px;
}
.title-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex: 1 1 auto;
}
.title {
  letter-spacing: -0.02em;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  flex: 1 1 auto;
  @include screen(mobile) {
    font-size: 16px;
  }
  @include screen(tablet) {
    font-size: 16px;
  }

  @include screen(small-desktop) {
    font-size: 16px;
  }
}
.update-passsword-button-container {
  width: 170px;
  font-size: 15px;
  border-width: 2px;
  margin-right: 20px;
  border-radius: 12px;
  color: #272b30;
  @include screen(mobile) {
    width: 60px;
  }

  @include screen(tablet) {
    width: 100px;
  }
}
.save-button-container {
  background-color: #4d8af0;
  color: #ffffff;
  width: 170px;
  font-size: 15px;
  border-width: 2px;
  border-radius: 12px;
}
.title-vertical-bar {
  height: 32px;
  width: 16px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #c0e1bc;
}
.readonly-input {
  color: #272b30;
  font-weight: 600;
  background-color: #9a9fa5;
  border-width: 0px;
  font-size: 15px;
  border-radius: 12px;
}
.focused-input:focus {
  border-color: #9a9fa5 !important;
}

.profile-content-title {
  color: #272b30;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 14px;
  margin-top: 17px;
}

.profile-mobile-email-container {
  display: flex;
}

.profile-role-container {
  margin-top: 40px;
}
.profile-square-pic {
  border-radius: 10px;
  object-fit: contain;
  width: 200px;
  height: 200px;
  @include screen(mobile) {
    width: 80px;
    height: 80px;
  }
  @include screen(tablet) {
    width: 160px;
    height: 160px;
  }
  @include screen(small-desktop) {
    width: 151px;
    height: 151px;
  }
}
.profile-input-container {
  margin-top: 14px;
  font-size: 16px;
  border: none;
  padding: 0px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  padding-left: 2px;
  box-shadow: none !important;
}

.profile-update-button {
  color: #4d8af0;
  cursor: pointer;
  margin-top: 14px;
}

.profile-pic-upload-container {
  margin-right: 60px;
  position: relative;
  display: flex;
  min-width: 200px;

  @include screen(small-desktop) {
    min-width: 151px;
    margin-right: 39px;
  }
}

.profile-edit-button-container {
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 25%);
  right: -7px;
  cursor: pointer;
  font-size: 16px;

  @include screen(mobile) {
    width: 23px;
    height: 23px;
    font-size: 14px;
  }
  // @include screen(tablet) {
  //   top: 50px;
  //   right: 15px;
  // }
  // @include screen(small-desktop) {
  //   top: 55px;
  //   right: 30px;
  // }
  // @include screen(big-screen) {
  //   top: 30px;
  //   right: 35px;
  // }
}

.profile-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;

  @include screen(mobile) {
    display: block;
    margin-top: 23px;

    .profile-button-save-container {
      width: 100% !important;
      max-width: 100% !important;
    }
    .profile-button-update-container {
      width: 100% !important;
      margin-top: 12px;
      max-width: 100% !important;
    }
  }
}

.profile-form-container {
  .chakra-form__error-message {
    margin-left: 10px;
  }
}
