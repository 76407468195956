@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.auth-container {
  background-color: #fcfcfc;
  display: flex;
  height: 100%;
  width: 100%;

  .auth-sidebar-container {
    width: 388px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;

    .auth-sidebar-title-container {
      margin-top: 24px;
      font-size: 28px;
      line-height: 1.04;
      letter-spacing: 1px;
      color: #272b30;
      font-weight: 700;
    }

    .auth-sidebar-desc-container {
      margin-top: 8px;
      font-size: 16px;
      color: #272b30;
      line-height: 1.81;
      letter-spacing: 1px;
    }

    @include screen(mobile) {
      display: none;
    }
    @include screen(tablet) {
      display: none;
    }
    @include screen(small-desktop) {
      display: none;
    }
  }

  .auth-content-container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include screen(mobile) {
      align-items: unset;
      padding: 35px;
      height: 100%;
      display: block;
      overflow-y: scroll;
      margin-top: 20px;
    }
  }
}

.feature-item-container {
  display: flex;
  margin-bottom: 15px;

  .feature-item-icon{
    padding-top: 2px;
  }

  img{
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  .feature-item-title-container {
    margin-left: 12px;
    color: #6f767e;
    line-height: 1.71;
    letter-spacing: -0.01em;
    font-size: 14px;
    font-weight: 600;
  }
}

.auth-other-screen-container {
  display: flex;
  align-items: flex-end;


  > a {
    display: none;
  }
  @include screen(mobile) {
    > a {
      display: block;
    }
    img {
      width: 47px;
      margin-bottom: 20px;
    }
  }
  @include screen(tablet) {
    > a {
      display: block;
    }
    img {
      width: 80px;
      margin-bottom: 45px;
    }
  }
  @include screen(small-desktop) {
    > a {
      display: block;
    }
    img {
      width: 80px;
      margin-bottom: 45px;
    }
  }
}

.invite-already-member{
  @include screen(mobile) {
    margin-top: 64px;
    justify-content: center;
  }
}