@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.invite-user-modal-container {
  min-width: 674px;
}
.invite-user-modal-role-container .ant-select-selector {
  padding: 0 !important;
}

.invite-user-form-container {
  width: 100%;
}

.invite-user-invited-link-container {
  display: flex;
  align-items: center;
  margin-top: 27px;

  @include screen(mobile) {
    flex-direction: column;
    justify-content: center;
  }
}

.invite-user-ask-text {
  margin-top: 35px;
  font-size: 14px;
  font-weight: 600;
  width: 211px;

  @include screen(mobile) {
    width: unset;
    text-align: center;
  }
}

.invite-user-buttons-container {
  margin-top: 37px;

  @include screen(mobile) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.invite-user-qr-code-container {
  margin-left: 88px;

  @include screen(mobile) {
    display: none;
  }
}

.invite-user-qr-code-mobile-container {
  display: none;
  @include screen(mobile) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.invite-user-send-link-text {
  margin-top: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.invite-user-header-container {
  padding-left: 40px !important;

  @include screen(mobile) {
    padding-left: 20px !important;
  }
  @include screen(tablet) {
    padding-left: 40px !important;
  }
}

.invite-user-content-container {
  padding-inline: 40px !important;
  padding-block: 0 20px !important;

  @include screen(mobile) {
    padding-inline: 20px !important;
    padding-block: 0 10px !important;
  }

  @include screen(tablet) {
    padding-inline: 40px !important;
    padding-block: 0 20px !important;
  }
}
