@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.shareHeading {
  flex: 1;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.4px;
  text-align: left;
  color: #1a1d1f;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Comment {
  width: 36px;
  height: 36px;
  //margin: 0 0 0 273px;
  padding: 8px;
  border-radius: 36px;
  background-color: #e1e1e1;
}

.location-share-member-item {
  .user-avatar-con {
    //width: 50px;
    flex-grow: 0;
  }
  .user-avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    object-fit: cover;
    flex-grow: 0;
    border-radius: 5.1px;
    background-color: var(--white);
  }

  .member-name {
    flex-grow: 0;
    font-family: Inter;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.15px;
    color: #1a1d1f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .auth-role-con {
    flex-grow: 0;
    font-family: Inter;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0 !important;
  }

  .member-divider {
    height: 1px;
    align-self: stretch;
    flex-grow: 0;
    margin: 10.5px 0 0;
    border-radius: 1px;
    background-color: #efefef;
  }
}
