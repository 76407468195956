@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search-list-container {
  padding: 20px 0;
  flex: 1 1;
  overflow-y: auto;
}
.search-item-container {
  display: flex;
  align-items: center;
  min-height: 44px;
  cursor: pointer;
  padding: 0px 40px;
  margin-top: 5px;
  color: #333b4f;
}

.search-item-container:hover {
  background-color: #eee;
}

.search-item-chapter-container {
  display: flex;
  min-height: 67px;
  cursor: pointer;
  padding: 0px 40px;
  margin-top: 5px;
  padding-top: 10px;
  color: #333b4f;
}

.search-item-chapter-container:hover {
  background-color: #eee;
}

.search-item-bundles-container {
  width: 11px;
  height: 11px;
  background-color: #f72e67;
  margin-right: 18px;
  border-radius: 11px;
}

.search-item-roles-container {
  width: 11px;
  height: 11px;
  background-color: #ff933d;
  margin-right: 18px;
  border-radius: 11px;
}

.search-empty-oh-crap-text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333b4f;
  margin-top: 30px;
}

.search-item-raw-content {
  color: #777;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 3.5px;
  max-width: 580px;
}

.search-item-category-icon {
  width: 23px;
  height: 23px;
  border-radius: 40px;
  border: solid 1px #70cee3;
  margin-left: -5px;
  margin-right: 12px;
  background-color: #c9f1fa;
}

.search-item-hover-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row-reverse;
  color: #999;
  display: none;
}

.search-item-hover-box {
  border-radius: 3px;
  background-color: #ccc;
  width: 19px;
  height: 19px;
  margin-left: 12px;
  display: none;
}

.search-item-container:hover .search-item-hover-container,
.search-item-chapter-container:hover .search-item-hover-container {
  display: flex;
}

.search-item-container:hover .search-item-hover-box,
.search-item-chapter-container:hover .search-item-hover-box {
  display: block;
}

.noResultFound {
  align-self: stretch;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14.6px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: #9e9e9e;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.recent-list-item {
  width: 79.9px;
  flex-grow: 0;
}

.status-icon-con {
  position: absolute;
  bottom: -6px;
  right: -8px;
  width: 24px;
  height: 24px;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin: -25px 0px 0 30px;
  //padding: 3.4px 3.8px 3.9px 2.5px;
  border-radius: 20px;
  border: solid 2px #fff;
  box-sizing: content-box;
}

.icon-person-filled {
  width: 14.4px;
  height: 14.4px;
  object-fit: contain;
}

.vector-card {
  width: 8.1px;
  height: 10.5px;
  margin-left: 2px;
  margin-top: 1px;
  background-color: #fff;
  border-radius: 1.5px;
}

.olena-sergienko-KTO4y6b8JMY-unsplash-1 {
  width: 48px;
  height: 48px;
  margin: 0 16px 0 0;
}

.iconCss {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px;
}

.iconCss:hover {
  background-color: #efefef;
}

.search-item-type-title {
  font-size: 12px;
  font-weight: 600;
  color: #6f767e;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;

  &:first-letter {
    text-transform: uppercase;
  }
}

.search-item-title {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  color: #1a1d1f;
}

.search-item-modified {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #999999;
  white-space: nowrap;

  &:first-letter {
    font-size: 15px;
    color: #6f767e;
  }
}
