@import '../../../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.manager-training-status-list-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .card-deck-list-container {
        display: flex;

        .list-img {
            min-height: 60px;
            height: 60px;
            max-height: 60px;
            min-width: 60px;
            width: 60px;
            max-width: 60px;
            border-radius: 5px;
            margin-right: 16px;
        }

        .text-container {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;

            .text-type {
                text-transform: capitalize;
                font-weight: 500;
                font-size: 12px;
                letter-spacing: -0.01em;
                color: #9a9fa5;
            }

            .text-content {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #1a1d1f;
            }
        }
    }

    .completed-part {
        color: #83bf6e;
        font-size: 25px;
        margin-right: 20px;
    }

    .milestone-container {
        position: relative;
        width: 100%;

        .icon-title-container {
            width: 100%;
            height: 60px;
            background-color: rgba(181, 228, 202, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            font-weight: 600;
            font-size: 14px;

            .icon-container {
                border-radius: 50%;
                background-color: #83bf6e;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.5rem;

                .icon {
                    width: 28px;
                    height: 28px;
                    padding: 7.6px 7.6px 7.6px 7.6px;
                }
            }
        }

        .icon-completed {
            color: #83bf6e;
            font-size: 25px;
            position: absolute;
            top: 16px;
            right: 18px;
        }
    }
}