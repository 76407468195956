.bundle-label-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.bundle-label-circle {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #4d8af0;
}

.bundle-label-title {
  font-size: 14px;
  line-height: 19px;
  color: #333b4f;
  margin-left: 12px;
}
