.sop-roles-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sop-roles-header-text {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #aaaaaa;
  text-transform: uppercase;
  margin-right: 20px;
}

.sop-roles-header-add-cta {
  color: #aaa;
  font-size: 18px;
  cursor: pointer;
}

.ant-popover-inner {
  border-radius: 10px;
  overflow: hidden;
}

.ant-popover-inner-content {
  max-height: 185px;
  overflow-y: auto;
}

.sop-roles-search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 240px;
  border-radius: 18px;
  padding-inline:18px;
  padding-block:5px
}

.sop-roles-search-bar div {
  display: flex;
  flex: 1 1 auto;  

}

.sop-roles-search-bar div input {
  outline: none;
  padding: 7px 5px 7px 15px;
  border: none;
  flex: 1 1 auto;
}

.add-role-popover-each-result {
  display: flex;
  align-items: center;
  padding-inline:20px;
  padding-block: 8px;
  margin: 5px 0;
  cursor: pointer;

}

.add-role-popover-each-result .each-result-color-box {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-right: 15px;
}

.add-role-popover-each-result .each-result-text {
  flex: 1 1 auto;
}

.add-role-popover-each-result .each-result-checked {
  height: 17px;
  width: 17px;
  color: #3ba578;
}

.create-new-bundle-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: #4d8af0;
  cursor: pointer;
}
