@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.edit-training-container {
  background-color: #ffffff;
  padding-inline: 34px;
  padding-block: 42px;
  border-radius: 12px;
  flex: 1 1 auto;
  display: flex;

  @include screen(tablet) {
    padding: 25px 29px;
  }
  @include screen(mobile) {
    padding: 23px 16px;
  }

  .cards-list-main-div {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    flex-direction: row;
  
    .cards-list-details {
        width: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
  
        .cards-icon-modal-main-div {
            height: 50px;
            width: 50px;
            background-color: rgb(244, 244, 244);
            display: flex;
            border-radius: 6.37px;
            margin-right: 1rem;
        }
    }
  }

  .edit-training-right-side {
    width: 250px !important;
    min-width: 250px;
    margin-left: 55px;

    .edit-training-right-side-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      /* identical to box height, or 200% */

      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #979ea6;
    }
  }
}
