@import '../../../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.custom-form-preview {
  height: inherit;
  background: #f3f3f3;
  //border-radius: 20px;

  font-family: 'Inter';
  font-style: normal;

  //  header scss start ===>
  &-header {
    padding-bottom: 22px;
    background: #ffffff;
    //border-top-left-radius: 20px;
    //border-top-right-radius: 20px;
  }

  img {
    margin-bottom: 16px;
  }

  &-title,
  &-description {
  }

  &-title {
    font-weight: 700;
    font-size: 16.5px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #000000;
    padding: 0 24px;
  }

  &-description {
    font-weight: 400;
    font-size: 13.2px;
    line-height: 20px;
    color: #272b30;
    padding: 0 24px;
    white-space: pre-line;
  }

  //  header scss end <===

  &-section {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #000000;

    padding: 15px 24px;
  }

  form {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-question {
    &-con {
      padding: 16px 24px;

      background: #ffffff;
      margin-bottom: 8px;
    }

    &-section-container {
    }

    &-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    &-label-container {
      display: flex;
      gap: 12px;
      margin-bottom: 16px;
    }

    &-acknowlege {
      color: #33383f;
    }

    &-sub-title {
      font-weight: 400;
      font-size: 12px;
      line-height: unset;

      color: #606365;
      margin-top: 4px;
    }

    &-sub-title:empty {
      display: none;
    }
  }
}
