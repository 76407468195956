@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.create-deck-container {
  .breadcrumb-container {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.15px;
    text-align: left;
    color: #6f767e;
    margin-bottom: 20px;
    margin-left: 1px;
    margin-top: 10px;
    cursor: pointer;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      // text-decoration: underline;
      color: #1a1d1f;
    }
  }
  .deck-cover {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .deck-left-card,
    .deck-right-card {
      background-color: #ffffff;
      border-radius: 15px;
      // width: 58%;
      // height: 75vh;
      padding: 30px;
    }

    .deck-left-card {
      overflow: hidden;
      .add-card-cover {
        .search-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 18px;

          .filter-cover {
            width: 48px;
            height: 48px;
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
          }

          .add-deck-btn {
            max-width: 132px;
            height: 48px;
            margin-left: 15px;
          }
        }
      }
      .card-list-cover {
        margin-top: 20px;
        height: 500px;
        overflow: scroll;
      }
    }
    .deck-right-card {
      width: 40%;

      .deck-cards-top {
        .title {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.6;
          letter-spacing: -0.4px;
          text-align: left;
          color: #1a1d1f;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 8px;
        }

        .visibility-check-cover {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: -0.02em;
            color: #2a85ff;
            margin-left: 10px;
          }

          .switch-label-cover {
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
              white-space: nowrap;
            }
          }
          .switch-cover {
            display: flex;
            align-items: center;

            p {
              color: #33383f;
              margin-right: 15px;
            }
          }
        }
      }

      .deck-cards-list {
        height: 820px;
        overflow-y: scroll;
      }
    }
  }
}

.total-text {
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: #979ea6;
}

.no-of-cards {
  //width: 62px;
  height: 21px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: center;
  color: #1a1d1f;
}
