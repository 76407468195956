.show-more-container {
  border-radius: 5px;
  border: solid 1px #ddd;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #4d8af0;
  height: 48px;
  cursor: pointer;
}
