@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.handbook-tab-container::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}

.handbook-tab-container::-webkit-scrollbar-thumb:vertical {
  background: #eee;
  border-radius: 10px;
}

.handbook-title-wrapper {
  display: flex;
  align-items: center;
}
.handbook-invite-user-container {
  display: none;
  @include screen(mobile) {
    display: block;
    margin-bottom: 17px;
  }
}
.handbook-title-container {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 17px;
  flex: 1 1 auto;

  @include screen(small-desktop) {
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.96px;
  }

  @include screen(small-desktop) {
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.96px;
  }

  @include screen(mobile) {
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
  }
}
.handbook-container {
  background-color: #ffffff;
  padding-inline: 34px;
  padding-block: 25px;
  border-radius: 12px;
  flex: 1 1 auto;
  // backface-visibility: hidden;
  // -webkit-transform: translate3d(0, 0, 0);
  // -moz-transform: translate3d(0, 0, 0);
  // transform: translate3d(0, 0, 0);

  @include screen(tablet) {
    padding: 25px 29px;
  }
  @include screen(mobile) {
    padding: 23px 16px;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: auto !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }
}

.setting-header-tablet-mobile-container {
  @include screen(tablet) {
    // display: flex;
    // flex-direction: row-reverse;
  }
  @include screen(mobile) {
    display: block;
  }

  .title-container {
    @include screen(tablet) {
      flex: 1 1 auto;
    }
  }
}

.handbook-nav-dropdown {
  width: 100%;
  margin-bottom: 26px;

  @include screen(mobile) {
    width: 100%;
    margin-bottom: 26px;
  }

  .chakra-divider {
    display: none;
  }
}
.handbook-header {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 30;
}
.handbook-header-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.handbook-header-text-container {
  flex-direction: column;
  margin-left: 15px;
  overflow: hidden;
}
.handbook-header-text {
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.handbook-search-container {
  display: flex;
  //margin-top: 20px;
  justify-content: space-between;
}
.handbook-header-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.handbook-card-list-flex {
  min-height: 550px;
  padding-top: 32px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;

  .handbook-card {
    position: relative;
    flex-direction: column;
    max-width: 300px;

    .handbook-card-container {
      background-color: #e4e4e4;
      //width: 100%;
      //aspect-ratio: 1.22;
      height: 180px;
      width: 220px;
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      position: relative;
    }
  }
}

.handbook-card-list {
  // flex-direction: row;
  // display: flex;
  // flex-wrap: wrap;
  // flex: 1;
  // row-gap: 1rem;
  // column-gap: 1rem;
  min-height: 550px;
  padding-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-gap: 1rem;
  grid-auto-flow: dense;

  .handbook-card {
    position: relative;
    flex-direction: column;
    max-width: 300px;

    .handbook-card-container {
      background-color: #e4e4e4;
      //width: 100%;
      //aspect-ratio: 1.22;
      height: 180px;
      width: 220px;
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      position: relative;
    }
  }
}
.handbook-deck {
  position: relative;
  margin: 16px;
  max-width: 300px;

  flex-direction: column;
}
.handbook-deck-number-of-cards {
  position: absolute;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  width: 60%;
  //height: 180px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0 12px 12px 0;
}
.handbook-deck-number {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 20px;
}
.handbook-deck-name {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 180px;
  max-width: 200px;
}
.handbook-deck-card-inner-container {
  background-color: #e4e4e4;
  height: 180px;
  width: 220px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
}
.title-with-search-container {
  display: flex;

  @include screen(mobile) {
    display: block;
  }
  // align-items: center;
}

.handbook-search {
  width: 435px !important;

  @include screen(mobile) {
    width: 100% !important;
  }
}

.temp-popup {
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  right: -179px;
  top: 37px;
  z-index: 99;
  justify-content: center;
  align-items: center;
  //background: rgba(0, 0, 0, 0.8);
}

.svg-handbook-disabled>path {
  fill: #c2cbd4;
  cursor: no-drop;
}

.svg-handbook-enabled {
  fill: rgb(111, 118, 126);
  cursor: pointer;
}
