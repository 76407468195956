.custom-tag-container {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  border: solid 1px;
  /* background-color: #3ba578; */
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  line-height: 16px;
  padding: 0px 4px;
  margin-top: 2px;
}
