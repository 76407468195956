@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.change-password-modal-container .ant-modal-header {
  border-radius: 10px 10px 2px 2px;
}

.change-password-modal-container .ant-form-item-label > label {
  color: #aaa;
  font-size: 14px;
  font-weight: 500;
}

.change-password-modal-container {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 62%);
  height: unset;
  padding: 30px;
  min-width: 700px;
  max-width: 740px;
  /* min-width: 772px; */
  /* min-height: 475px; */
}
.modal-title {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #1a1d1f;
}
.old-password {
  width: 48%;

  @include screen(mobile) {
    width: 100%;
  }
}
.input-label {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.14px;
  text-align: left;
  color: #33383f;
  font-size: 14px;
  font-weight: 600;
  margin-block: 12px;
}

.change-password-modal-container .ant-modal-body {
  height: unset;
}
/* TODO:Remove all important tag */

.login-heading-container {
  text-align: center;
  color: #1a1d1f;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -1.44px;

  @include screen(mobile) {
    text-align: left;
    font-size: 45px;
  }
}

.login-sub-heading-container {
  margin-top: 29px;
  text-align: center;
  color: #1a1d1f;
  line-height: 1.71;
  letter-spacing: -0.14px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  @include screen(mobile) {
    text-align: left;
    width: unset !important;
  }
}

.login-form-parent-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.change-password-form-container {
  width: 100%;
  @include screen(mobile) {
    width: unset;
  }
}

.login-error-message {
  margin-top: 12px;
  text-align: center;
  color: #ff6a55;
}

.login-remember-forgot-container {
  margin-top: 22px;
  display: flex;
  align-items: center;

  .chakra-checkbox__label {
    font-size: 14px;
  }

  .login-forgot-text-container {
    font-weight: 500;
    line-height: 1.43;
    text-align: right;
    color: #2a85ff;
    cursor: pointer;
  }
}

.login-check-box-container {
  .chakra-checkbox__control {
    border-radius: 5px !important;
  }
}

.new-confirm-password-container {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  @include screen(mobile) {
    display: block;
  }
}

.confirm-new-password-container {
  width: 50%;
  @include screen(mobile) {
    width: 100%;
    margin-right: 0px;
  }
}

.new-password-container {
  width: 50%;
  margin-right: 20px;
  @include screen(mobile) {
    width: 100%;
    margin-right: 0px;
  }
}
