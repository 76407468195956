@import 'mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700&display=swap');
@import '~video-react/styles/scss/video-react'; // or import scss

@font-face {
  font-family: 'SnellRoundhand';
  //font-size: 18px;
  //size-adjust: 128%;
  src: local('SnellRoundhand'),
    url('./assets/fonts/SnellRoundhand.ttf') format('truetype');
}
@font-face {
  font-family: 'SignPainter';
  //font-size: 20px;
  //size-adjust: 100%;
  //src: local('SignPainter'),
  //  url('./assets/fonts/SignPainter.ttf') format('truetype');
  src: local('SignPainter'),
    url('./assets/fonts/SignPainter HouseScript Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Noteworthy';
  //font-size: 15px;
  //size-adjust: 107%;
  src: local('Noteworthy'),
    url('./assets/fonts/Noteworthy.ttf') format('truetype');
}
@font-face {
  font-family: 'PartyLETPlain';
  //font-size: 21px;
  //size-adjust: 150%;
  src: local('PartyLETPlain'),
    url('./assets/fonts/Party Plain.ttf') format('truetype');
}
@font-face {
  font-family: 'Bradley Hand';
  //font-size: 16px;
  //size-adjust: 114%;
  src: local('Bradley Hand'),
    url('./assets/fonts/Bradley Hand.ttf') format('truetype');
}
@font-face {
  font-family: 'Zapfino';
  //font-size: 12px;
  //size-adjust: 140%;
  //src: local('Zapfino'), url('./assets/fonts/Zapfino.ttf') format('truetype');
  src: local('ZapfinoExtraLTPro'),
    url('./assets/fonts/ZapfinoExtraLTPro.otf') format('opentype');
}

body {
  font-family: 'Inter', 'Open Sans', sans-serif !important;
  overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

html,
body {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

#root {
  height: inherit;
}

.App-link {
  color: #61dafb;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  background-color: green !important; /*Overridden property*/
}

.ProseMirror {
  padding: 0 !important;
  padding-left: 0 !important;
  overflow: scroll;
  max-width: unset !important;
}

p {
  line-height: 25px !important;
}

h1 {
  display: block;
  font-size: 2em !important;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  margin-bottom: 0.5em !important;
}

h2 {
  display: block;
  font-size: 1.5em !important;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  margin-bottom: 0.5em !important;
}

h3 {
  display: block;
  font-size: 1.17em !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  margin-bottom: 0.5em !important;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
  min-width: 40px;
}

.custom-image {
  border-radius: 8px;
}

.custom-video {
  border-radius: 8px;
}

.app-main-container {
  margin-left: 70px;
  margin-right: 70px;
}

.seen-by-user-container {
  display: flex;
  // color: rgba(20, 20, 20, 0.4);
  align-items: center;
  // font-size: 12px;
  //font-weight: 500;
  line-height: 12px;
  cursor: pointer;
  color: #9a9fa5;
  font-size: 13px;
  font-weight: 600;
}

.seen-by-user-container:hover {
  color: rgb(77, 138, 240);
}

.message__info__wrapper div::before {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(20, 20, 20, 0.4);
}

.seen-by-user-container-separator {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: rgba(20, 20, 20, 0.4);
  margin-left: 8px;
  margin-right: 8px;
}

li p {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen {
  @media (max-width: 768px) {
    .custom-image-medium {
      margin-left: 0;
      height: auto !important;
      width: 100% !important;
      border-radius: 8px;
    }

    .custom-video {
      width: 100% !important;
      height: auto !important;
    }
  }

  @media (min-width: 1300px) and (max-width: 1350px) {
    .app-main-container {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  @media (min-width: 1351px) and (max-width: 1399px) {
    .app-main-container {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}

::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}
