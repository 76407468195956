.search-list-container {
  padding: 20px 0px;
  flex: 1 1;
  overflow-y: auto;
}
.search-item-container {
  display: flex;
  align-items: center;
  min-height: 44px;
  cursor: pointer;
  padding: 0px 40px;
  margin-top: 5px;
  color: #333b4f;
}

.search-item-container:hover {
  background-color: #eee;
}

.search-item-chapter-container {
  display: flex;
  min-height: 67px;
  cursor: pointer;
  padding: 0px 40px;
  margin-top: 5px;
  padding-top: 10px;
  color: #333b4f;
}

.search-item-chapter-container:hover {
  background-color: #eee;
}

.search-item-bundles-container {
  width: 11px;
  height: 11px;
  background-color: #f72e67;
  margin-right: 18px;
  border-radius: 11px;
}

.search-item-roles-container {
  width: 11px;
  height: 11px;
  background-color: #ff933d;
  margin-right: 18px;
  border-radius: 11px;
}

.search-empty-oh-crap-text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333b4f;
  margin-top: 30px;
}

.search-item-raw-content {
  color: #777;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 3.5px;
  max-width: 580px;
}

.search-item-category-icon {
  width: 23px;
  height: 23px;
  border-radius: 40px;
  border: solid 1px #70cee3;
  margin-left: -5px;
  margin-right: 12px;
  background-color: #c9f1fa;
}

.search-item-hover-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row-reverse;
  color: #999;
  display: none;
}

.search-item-hover-box {
  border-radius: 3px;
  background-color: #ccc;
  width: 19px;
  height: 19px;
  margin-left: 12px;
  display: none;
}

.search-item-container:hover .search-item-hover-container,
.search-item-chapter-container:hover .search-item-hover-container {
  display: flex;
}

.search-item-container:hover .search-item-hover-box,
.search-item-chapter-container:hover .search-item-hover-box {
  display: block;
}
