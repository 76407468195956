.recent-search-list-container {
  padding: 20px 0px;
  overflow-y: auto;
}
.recent-search-item-container {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
  padding: 0px 40px;
  margin-top: 5px;
}

.recent-search-item-container:hover {
  background-color: #eee;
}

.recent-search-item-title {
  margin-left: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 480px;
  color: #333b4f;
}

.recent-search-item-time-container {
  font-size: 12px;
  flex: 1 1;
  text-align: right;
  color: #777;
}
