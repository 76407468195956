@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.authorization-level-container {
  @include screen(mobile) {
    flex-direction: column;
  }

  .authorization-level-item-container {
    flex: 1 1;
    border: 1px solid rgba(154, 159, 165, 0.3);
    border-radius: 9px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-right: 10.5px;
    cursor: pointer;

    path {
      fill: #6f767e;
    }
    ellipse {
      fill: #6f767e;
    }

    .selected {
      background: #ffffff;
      border: 0.954128px solid #2a85ff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    }

    @include screen(mobile) {
      margin-bottom: 10px;
      margin-right: 0px;
      padding: 15px 23px;
      flex-direction: row;
    }

    &.disabled {
      cursor: no-drop;
      opacity: 0.5;
      user-select: none;
    }
  }

  .authorization-level-item-container.selected {
    background: #ffffff;
    border: 0.954128px solid #2a85ff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    path {
      fill: #2a85ff;
    }
    ellipse {
      fill: #2a85ff;
    }
  }

  .authorization-level-item-content {
    text-align: center;

    @include screen(mobile) {
      text-align: left;
      margin-left: 21px;
    }
  }

  .authorization-level-item-title {
    margin-top: 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #1a1d1f;
  }

  .authorization-level-item-desc {
    font-weight: 600;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #9a9fa5;
  }
}
