@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-edit-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.card-editor-background-container {
  width: 58.9px;
  height: 58.9px;
  border-radius: 58.9px;
  box-shadow: 0 7px 22px 0 rgb(86 84 104 / 20%);
  margin-right: 11px;
  cursor: pointer;
}

.card-editor-description-container {
  border: 1px solid #ddd;
  margin-top: 12px;
  border-radius: 12px;
  background-color: #f4f4f4;
  padding: 12px;
  min-height: 130px;
}

.card-editor-empty-image-container {
  width: 86px;
  height: 86px;
  border: 1px dashed #b3b3b4;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-editor-image-label {
  position: relative;
  cursor: pointer;

  .image-index-count {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background: #2a85ff;
    border-radius: 50%;
    transform: translate(40%, -40%);
    font-size: 11px;
    font-weight: 700;
    color: white;
  }
}

.card-editor-image-container {
  width: 86px;
  height: 86px;
  border-radius: 7px;
  margin-right: 10px;
}

// quiz card editor styles

.quizCardContainer {
  .answersContainer {
    .quizAnswerCover {
      display: flex;
      //align-items: center;
      margin-top: 12px;

      .quizAnswerCorrectBtn {
        padding: 0 37px;
        margin-right: 10px;
        margin-left: 8px;
        max-width: 106px;
      }

      .deleteOptionIcon {
        margin-top: 9px;
        cursor: pointer;

        &:hover {
          path {
            fill: #000000;
          }
        }
      }
    }

    .addOptionsBtn {
      max-width: 129px;
      margin-top: 18px;
    }
  }
}

.create-card-check-box-container {
  .chakra-checkbox__control {
    border-radius: 5px !important;
  }

  .chakra-checkbox__label {
    font-size: 14px;
    font-weight: 500;
  }
}

.switch-cover {
  display: flex;
  align-items: unset;

  p {
    color: #33383f;
    margin-right: 15px;
  }
}

.footer {
  //width: 56%;
  background-color: #fff;
  //height: 100px;
  //position: absolute;
  //padding-bottom: 60px;
  //bottom: 0;
  /* margin-bottom: 50px; */
  //padding-top: 15px;
  display: flex;
  justify-content: space-between;
}
