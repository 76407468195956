/* TODO:Remove all important tag */

.business-name {
  display: flex;
  border-width: 1px !important;
  border-color: #dddddd !important;
  border: solid;
  height: 40px;
  padding-inline: 12px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 350.98px;
}

.share-login-container {
  display: flex;
  border-width: 1px !important;
  border-color: #dddddd !important;
  border: solid;
  height: 40px;
  padding-inline: 12px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 350.98px;
  width: 130px;
}
.go-to-dashboard-button {
  border: solid;
  border-width: 1px;
  border-color: #dddddd;
}
.profile-pic {
  border: solid;
  border-width: 1px;
  border-radius: 50%;
  border-color: #ffffff;
}

.public-header-desktop-container {
  display: flex;
  margin-left: 78px;
  margin-right: 70px;
}

.public-header-mobile-container {
  display: none;
}

@media screen {
  @media (max-width: 768px) {
    /* body,
    html {
      height: unset;
      overflow-y: scroll;
    }
    #root {
      height: unset;
    } */
    .custom-image {
      margin-left: 0px;
      height: auto !important;
      width: 100% !important;
    }

    .custom-image-medium {
      margin-left: 0px;
      height: auto !important;
      width: 100% !important;
      border-radius: 8px;
    }

    .custom-video {
      width: 100% !important;
      height: auto !important;
      border-radius: 8px;
    }

    .ProseMirror {
      padding-bottom: 0px !important;
    }
    .ProseMirror > p:last-child {
      margin-bottom: 0px !important;
    }
    .ProseMirror > ul:last-child {
      margin-bottom: 0px !important;
    }
    .ProseMirror > ol:last-child {
      margin-bottom: 0px !important;
    }
    .ProseMirror > dl:last-child {
      margin-bottom: 0px !important;
    }

    .public-header-mobile-container {
      display: block;
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0 37px;
      background-color: #fff;
      min-height: 70px;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    }

    .public-header-desktop-container {
      display: none;
    }
  }
}
