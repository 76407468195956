@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.training-item-type {
  text-transform: capitalize;
  width: 100%;
  height: 45px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.12px;
  text-align: left;
  color: #ffffff;
}

.training-item-name {
  width: 300px;
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.isChecked {
  background-color: #77bd76;
  border-radius: 20px;
  padding: 2.5px 2.5px 1.9px 1.9px;
  width: 20px;
  height: 20px;
  flex-grow: 0;
}

.unChecked {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  padding: 2.5px 2.5px 1.9px 1.9px;
  border-radius: 13.7px;
  border: solid 1.2px #c4c4c4;
  border-radius: 20px;
}

.milestone-box {
  height: 40px;
  flex-grow: 0;
  text-align: center;
  padding-top: 5px;
  background-color: #83bf6e;
  padding-left: 185px;
}

.milestone-txt {
  width: 100%;
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.14px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: self-end;
}

.active-background {
  background-color: #333a42;
}
