@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import '../Login/Login.scss';

.back-to-signin-container {
  display: flex;
  align-items: center;
  color: #6f767e;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;

  .back-to-signin-text {
    line-height: 1.71;
    letter-spacing: -0.14px;
    font-weight: 600;
    margin-left: 5px;
  }
}
