@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.training-path-main-container {
  align-items: center;
  gap: 67px;
  margin: 82px 33px 21.7px;
  padding: 0;
  //height: auto;
  //opacity: 0.95;
  background-color: #263238;
  height: 100%;
}

.Quiz---Choices-1 {
  background-color: black;
  flex-grow: 0;
  width: 100%;
  height: auto;
}

.training-path-sub-container {
  width: 100%;
  height: 100%;
  // opacity: 0.95;
  background-color: rgb(38, 50, 56, 0.95);
  position: absolute;
  z-index: 5;
  top: 0px;
  left: 0px;
}

.ShowTraining {
  width: 20%;
  height: 32px;
  margin: 21px 1243px 0 12px;
  font-family: Inter;
  font-size: 25px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: -0.28px;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.css-1lx0azy {
  background: none !important;
}

.progressBarBox {
  margin: 21px 1243px 0 12px;
}

.css-c91llq {
  height: 700px;
}

.training-listing {
  width: 529px;
  height: calc(100vh - 180px);
  margin: 149px 877px 14px 33px;
  //padding: 27px 15px 28px;
  border-radius: 10px;
  box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.25);
  background-color: #495058;
  position: absolute;
  z-index: 5;
  bottom: 120px;
  left: 0px;
  // overflow-y: scroll;

  .training-listing-close-icon-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;

    .training-listing-close-icon-img {
      cursor: pointer;
      border-radius: 100%;
      height: 40px;
      width: 40px;
      padding: 5px;
      margin: 0px;
    }
  }

  .training-listing-details {
    overflow-y: scroll;
    height: calc(100vh - 260px);
    padding-bottom: 1rem;
  }
}
