@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.member-list-item {
  width: 50px;
  flex-grow: 0;
}
.memberImage {
  width: 50px;
  height: 50px;
  cursor: pointer;
  object-fit: cover;
  flex-grow: 0;
  border-radius: 5.1px;
  background-color: var(--white);
}

.title {
  flex-grow: 0;
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.14px;
  text-align: left;
  color: #1a1d1f;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.role {
  padding-top: 5px;
  flex-grow: 0;
  margin: 0px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: left;
  color: #9a9fa5;
}

.divider {
  width: 423px;
  height: 1px;
  align-self: stretch;
  flex-grow: 0;
  margin: 10.5px 0 0;
  border-radius: 1px;
  background-color: #efefef;
}

.location-bullet {
  width: 4px;
  height: 4px;
  flex-grow: 0;
  background-color: #354b76;
  border-radius: 2px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
}
