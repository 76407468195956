.home-name-container {
  font-size: 28px;
  font-weight: 700;
}

.home-welcome-back-text {
  color: #777;
  font-size: 18px;
}

.activity-container {
  height: max-content;
  position: sticky;
  top: 0;
  > div {
    height: unset !important;
    max-height: calc(100% - 96px - 34px);
  }
}

@media only screen and (max-width: 767px) {
  /*@content; mobile */
  .activity-container {
    > div {
      max-height: calc(100% - 96px - 28px);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  /*@content; tablet*/

  .activity-container {
    > div {
      max-height: calc(100% - 96px - 38px);
    }
  }
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
  /*@content; small-desktop*/

  .activity-container {
    > div {
      max-height: calc(100% - 96px - 38px);
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
  .recommend-train-container {
    width: 50%;
  }

  .activity-container {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .recommend-train-container {
    width: 60%;
  }

  .activity-container {
    width: 40%;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .recommend-train-container {
    width: 70%;
  }

  .activity-container {
    width: 30%;
  }
}

@media only screen and (min-width: 1201px) {
  .recommend-train-container {
    width: 70%;
  }

  .activity-container {
    width: 30%;
  }
}
