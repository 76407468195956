@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.activity-feed-tag-container {
  width: 16px;
  height: 32px;
  background: #b1e5fc;
  border-radius: 4px;
}

.activity-feed-title-container {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #1a1d1f;
  margin-left: 10px;
}

.activity-dropdown-container {
  max-width: 40%;
  padding-left: 10px;

  @include screen(big-screen) {
    max-width: 50%;
  }
}

.days-ago-text {
  background-color: #efefef;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 7px;
  border-radius: 4px;
  color: #6f767e;
}

.user-feed-img {
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  width: 56px;
  min-width: 56px;
  max-width: 56px;
  border-radius: 8.1px;
}
