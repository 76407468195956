@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.milestone-main-div {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(181, 228, 202, 0.5);
}


.milestone-main-div:hover, body > .milestone-main-div {
  .sel-card-left, .remove-milestone, .edit-milestone {
    opacity: 1;
    visibility: visible;
  }
}

.milestone-container {
  height: 48px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: #000000;
}

.sel-card-left {
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sel-card-left, .remove-milestone, .edit-milestone {
  opacity: 0;
  visibility: hidden;
}


.milestone-flag-img-div {
  border-radius: 50%;
  background-color: #83bf6e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  .flagImage {
    width: 28px;
    height: 28px;
    // flex-grow: 0;
    // margin: 0 7px 0 0;
    padding: 7.6px 7.6px 7.6px 7.6px;
    //border-radius: 49.6px;
    //background-color: #83bf6e;
  }
}