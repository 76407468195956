@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.invite-registration-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.invite-registration-content-container {
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 80px;
}

.invite-registration-welcome-text {
  margin-top: 30px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.invite-registration-welcome-desc {
  margin-top: 8px;
  color: #999;
  font-size: 18px;
  margin-bottom: 40px;
}

.invite-registration-input-container {
  border: none !important;
  box-shadow: none;
  border-bottom: 2px solid #4d8af0 !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
}

.ant-form-item-label > label {
  color: #4d8af0;
}
.invite-registration-revoked-text {
  width: 500px;
}

@media screen {
  @media (max-width: 768px) {
    .invite-registration-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .invite-registration-content-container {
      margin-top: 60px;
    }

    .invite-registration-welcome-text {
      margin-top: 20px;
      font-size: 25px;
      font-weight: bold;
    }

    .invite-registration-welcome-desc {
      margin-top: 5px;
      color: #999;
      font-size: 14px;
      margin-bottom: 20px;
    }

    .invite-registration-revoked-text {
      width: 290px;
    }
  }
}

.invite-register-welcome-business-text {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  color: #6f767e;
  margin-top: 8px;
  text-align: center;

  @include screen(mobile) {
    text-align: left;
  }
}

.auth-other-screen-container {
  flex-grow: 1;
}

.invite-form-container {
}

.invite-privacy-policy {
  margin-top: 14px;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #919fa5;
}

.invite-privacy-policy > span {
  font-weight: 600;
  color: #2a85ff;
}

.invite-already-member {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #9a9fa5;
}

.invite-already-member > a {
  color: #2a85ff;
  padding: 0 4px;
}
