@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.error-container {
  padding: 48px 47px;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;

  img {
    width: 46.2px;
    height: 40px;
  }

  .error-content-container {
    display: flex;

    justify-content: center;

    flex: 1 1 auto;
    margin-top: 48px;
  }

  .error-title {
    width: 480px;
    color: #000;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    line-height: 1.25;
    @include screen(mobile) {
      width: 336px;
      font-size: 28px;
    }
  }

  .error-description {
    margin-top: 24px;
    line-height: 1.6;
    letter-spacing: -0.15px;
    text-align: center;
    color: #33383f;
    font-size: 15px;
    width: 329px;
    @include screen(mobile) {
      width: 300px;
      font-size: 12px;
    }
  }
}
