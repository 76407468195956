.ant-divider {
  display: flex !important;
  /* justify-content: center!important;
    align-items: center!important;
    align-content: center!important;
    align-self: center!important; */
}

.ant-input-lg {
  font-size: 25px !important;
}
.add-sop-file-upload {
  border-width: 1px !important;
}
.add-sop-added-files {
  border-width: 1px !important;
  border: solid;
  border-color: #dddddd;
}
