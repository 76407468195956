/* TODO:Remove all important tag */
.ant-menu {
  border-width: 1px;
  color: #999999 !important;
  background-color: #ffffff !important;
}
.ant-menu-submenu-title {
  font-weight: 500 !important;
}
.ant-menu-submenu-title:hover {
  color: #4d8af0 !important;
}
.onboarding-menu .ant-menu-item-selected {
  color: #4d8af0 !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
}
.ant-menu-item:hover {
  color: #4d8af0 !important;
  font-weight: 700 !important;
  background-color: #ffffff !important;
}
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 0 !important;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu {
  color: #000000 !important;
  font-weight: 500 !important;
}
.ant-menu-submenu-selected {
  color: #333b4f !important;
  font-weight: 500 !important;
}
.progress-bar {
  display: flex;
  max-width: 66%;
  margin-left: -22px !important;
  border-color: #52b596;
  align-items: flex-start;
  justify-content: flex-start;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #52b596;
}
.ant-menu-inline.ant-menu-root
  .ant-menu-item.ant-menu-item-selected.onboarding-sop-category-menu-item
  > .ant-menu-title-content {
  font-weight: bold;
}
/* .ant-menu-inline.ant-menu-root .ant-menu-item.ant-menu-item-active.ant-menu-item-selected.onboarding-sop-category-menu-item > .ant-menu-title-content {
    color: #4D8AF0;
} */
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 8px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: 73%;
  height: 3px;
}
.progress-dot {
  margin-left: 0px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 20px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
}
.onboarding-menu {
  border: solid;
  border-width: 1px !important;
  border-color: #dddddd;
}
.onboarding-company-info-menu-item {
  background-color: #ffffff;
  border-right-width: 1px !important;
  border-right: solid;
  border-right-color: #dddddd;
}
.onboarding-sop-category-menu-item {
  background-color: #ffffff;
  border-right-width: 1px !important;
  border-right: solid;
  border-right-color: #dddddd;
}
.onboarding-sop-category-submenu {
  font-weight: 500 !important;
  color: #000000 !important;
}
