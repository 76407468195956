/* TODO:Remove all important tag */

.login-heading-container {
  text-align: center;
  color: #1a1d1f;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -1.44px;

  @include screen(mobile) {
    text-align: left;
    font-size: 45px;
  }
}

.login-sub-heading-container {
  margin-top: 29px;
  text-align: center;
  color: #1a1d1f;
  line-height: 1.71;
  letter-spacing: -0.14px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  @include screen(mobile) {
    text-align: left;
    width: unset !important;
  }
}

.login-form-parent-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form-container {
  width: 300px;
  margin: auto;
  @include screen(mobile) {
    width: unset;
  }
}

.login-error-message {
  margin-top: 12px;
  text-align: center;
  color: #ff6a55;
}

.login-remember-forgot-container {
  margin-top: 22px;
  display: flex;
  align-items: center;

  .chakra-checkbox__label {
    font-size: 14px;
  }

  .login-forgot-text-container {
    font-weight: 500;
    line-height: 1.43;
    text-align: right;
    color: #2a85ff;
    cursor: pointer;
  }
}

.login-check-box-container {
  .chakra-checkbox__control {
    border-radius: 5px !important;
  }
}
