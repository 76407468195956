.qz-textarea {
  width: 100%;
  border-radius: 12px;

  font-family: 'Inter', sans-serif;

  color: #33383f;
  font-size: 12px;
  font-weight: 500;

  /*  &:focus-within {
    border-color: rgba(42, 133, 255, 0.35);
  }

  &[aria-invalid='true'] {
    background-color: rgba(255, 188, 153, 0.3);

    border-color: rgba(42, 133, 255, 0.35);
  }
  */

  .ql-container.ql-snow {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    border: unset;
    border-radius: 12px;

    .ql-editor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      p {
        line-height: unset !important;
      }
    }
  }
}
