@import '../../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
  position: relative;

  .card-left {
    margin-right: 40px;
  }
  .sel-card-left {
    min-width: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .sel-card-left,
  .remove-card,
  .edit-card,
  .action-button {
    // opacity: 0;
    // visibility: hidden;
    transition: all ease-in-out 100ms;
  }

  .edit-card .edit-icon-wrapper,
  .remove-card .close-icon-wrapper {
    opacity: 0;
    visibility: hidden;
  }

  .card-right {
    display: flex;
    align-items: flex-start;
    flex: 1;
    .img-cover {
      width: 66px;
      height: 63px;
      border-radius: 8px;
      margin-right: 12px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .card-info-cover {
      width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .card-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #1a1d1f;
      }
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #9a9fa5;
      }
    }

    .close-icon-cover {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
}

.card-container:hover,
body > .card-container {
  .sel-card-left,
  .remove-card,
  .edit-card,
  .action-button {
    opacity: 1;
    visibility: visible;
  }
  .edit-card .edit-icon-wrapper,
  .remove-card .close-icon-wrapper {
    opacity: 1;
    visibility: visible;
  }
}

.drag-icon {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  img {
    width: 26px;
    height: 26px;
    min-width: 26px;
  }
}

.card-text-elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
.card-text-elips-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.addCardsCreateDeck-card-container {
  // display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
  position: relative;
  width: 100%;

  .addCardsCreateDeck-card-left {
    margin-right: 40px;
  }
  .addCardsCreateDeck-sel-card-left {
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .addCardsCreateDeck-card-right {
    display: flex;
    align-items: flex-start;
    flex: 1;
    .addCardsCreateDeck-img-cover {
      width: 66px;
      height: 63px;
      border-radius: 8px;
      margin-right: 12px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .addCardsCreateDeck-card-info-cover {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .addCardsCreateDeck-card-info-div {
        width: 50%;
      }

      @media (max-width: 1258px) {
        .addCardsCreateDeck-card-info-div {
          width: 40%;
        }
      }

      .addCardsCreateDeck-card-info-actions {
        // width: 100%;
        justify-content: flex-end;

        opacity: 0;
        visibility: hidden;
      }

      @media (max-width: 1258px) {
        .addCardsCreateDeck-card-info-actions {
          // width: 100%;
        }
      }

      .addCardsCreateDeck-card-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #1a1d1f;
      }
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #9a9fa5;
      }
    }

    .addCardsCreateDeck-close-icon-cover {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
}

.addCardsCreateDeck-card-container:hover,
body > .addCardsCreateDeck-card-container {
  .addCardsCreateDeck-card-info-actions {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.addCardsCreateDeck-drag-icon {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.addCardsCreateDeck-card-text-elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
.addCardsCreateDeck-card-text-elips-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.addedCardsCreateDeck-card-container {
  // display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
  position: relative;
  width: 100%;

  .addedCardsCreateDeck-card-left {
    margin-right: 40px;
  }
  .addedCardsCreateDeck-sel-card-left {
    width: 37px;
    min-width: 37px;
    max-width: 37px;
    cursor: pointer;

    opacity: 0;
    visibility: hidden;
  }

  .addedCardsCreateDeck-card-right {
    display: flex;
    align-items: flex-start;
    flex: 1;
    .addedCardsCreateDeck-img-cover {
      width: 66px;
      min-width: 66px;
      max-width: 66px;
      height: 63px;
      min-height: 63px;
      max-height: 63px;
      border-radius: 8px;
      margin-right: 12px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .addedCardsCreateDeck-card-info-cover {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .addedCardsCreateDeck-card-info-div {
        //width: 80%;
        overflow: hidden;
      }

      .addedCardsCreateDeck-card-info-actions {
        // width: 100%;
        justify-content: flex-end;
      }

      .addedCardsCreateDeck-card-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #1a1d1f;
      }
      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #9a9fa5;

        &.date {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .addedCardsCreateDeck-card-actions {
      display: flex;
      padding: 0 6px;
      opacity: 0;
      visibility: hidden;
    }

    .addedCardsCreateDeck-close-icon-cover {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
}

.addedCardsCreateDeck-card-container:hover,
body > .addedCardsCreateDeck-card-container {
  .addedCardsCreateDeck-card-actions,
  .addedCardsCreateDeck-sel-card-left {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.addedCardsCreateDeck-drag-icon {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.addedCardsCreateDeck-card-text-elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
.addedCardsCreateDeck-card-text-elips-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //max-width: 300px;
}
