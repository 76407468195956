@import '../../../sop-web/src/mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.player-wrapper {
  position: relative;
  border-radius: 20px;
  height: 100%;
  padding-top: 190%; /* Player ratio: 100 / (1280 / 720) */
}
.video-caption {
  position: absolute;
  height: auto;
  min-height: 87px;
  color: rgb(255, 255, 255);
  bottom: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  background: linear-gradient(0deg, #000000 1.03%, rgba(0, 0, 0, 0) 30%);
  /* transform: rotate(-180deg); */
  padding-left: 15px;
  font-size: 16px;
  //line-height: 24px;
  padding-bottom: 15px;
  padding-right: 15px;

  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: auto;
}
.caption-text-container {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.caption-title {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 24px;
  color: #ffffff;
}
.react-player {
  position: absolute;
  border-radius: 20px;
  overflow: hidden;
  top: 0;
  left: 0;
  height: auto;
  z-index: 100;
}
.action-type-content-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.action-type-card-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  .action-type-icon-background-container {
    height: 380px;
    min-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.progress-bar-wrapper {
  position: absolute;
  bottom: 0px;
  // z-index: 100000;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.action-type-content-title-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 47px 0px;
}
.caption-text {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 16px;
  font-weight: 450;
  font-style: normal;
  line-height: 24px;
  color: #ffffff;
}
.caption-duration {
  font-size: 16px;
  font-weight: 450;
  font-style: normal;
  line-height: 24px;
}

.action-type-content-button-container {
  padding: 19px 17px;
  z-index: 1;
}

.quiz-type-card-container {
  height: inherit;
  display: flex;
  flex-direction: column;

  .quiz-type-card-question-container {
    background-color: #2a85ff;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 22px;
    padding: 24px;
    margin-bottom: 24px;

    p {
      line-height: 32px !important;
    }
  }

  .quiz-type-card-choice-container {
    padding-left: 24px;
    padding-right: 24px;
    //height: 60px;
  }

  .quiz-type-card-choice-container.wrong {
    background-color: rgba(255, 188, 153, 0.4);
  }
  .quiz-type-card-choice-container.correct {
    background-color: rgba(181, 228, 202, 0.4);
  }

  .quiz-type-card-choice {
    //height: inherit;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    // text-align: center;
    color: #000000;
    padding-bottom: 16px;
    padding-top: 16px;
    cursor: pointer;
  }

  .quiz-card-please-select-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #2a85ff;
    margin-bottom: 10px;
  }

  .quiz-type-card-choice-icon {
    width: 17px;
    height: 12px;
    margin-left: 4px;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  .quiz-type-card-choice-icon.selected {
    svg {
      path {
        fill: #2a85ff;
      }
    }
  }
  .quiz-type-card-choice-icon.correct {
    svg {
      path {
        fill: #5d9d47;
      }
    }
  }
  .quiz-type-card-choice-icon.wrong {
    svg {
      path {
        fill: #ff6a55;
      }
    }
  }
}

.mixed-card-title-container {
  padding: 20px 20px 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
}

.image-bottom-gradient {
  position: absolute;
  color: rgb(255, 255, 255);
  bottom: 0px;
  /* height: 142px; */
  width: 100%;
  // background: linear-gradient(0deg, #000000 42.03%, rgba(0, 0, 0, 0) 99.21%);

  // background: linear-gradient(0deg, #000000 1.03%, rgba(0, 0, 0, 0) 50%);
  /* transform: rotate(-180deg); */
  // padding-top: 72px;
  // padding-left: 30px;
  // font-size: 16px;
  // line-height: 24px;
  // padding-bottom: 20px;
  // padding-right: 30px;
  //height: 100%;
  display: flex;
  flex-direction: column-reverse;

  .image-gradient-child-container {
    background: linear-gradient(0deg, #000000 1.03%, rgba(0, 0, 0, 0) 90%);
    z-index: 200;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
  }
}

.card-preview-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

.card-preview-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}
