/* TODO:Remove all important tag */

.ant-select-dropdown {
  border-radius: 10px !important;
}

/* .ant-select-dropdown-menu.ant-select-dropdown-menu-root.ant-select-dropdown-menu-vertical {
    padding: 200px !important;
    margin: 120px !important;
} */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: rgba(108, 99, 255, 0.1) !important;
}
.company-info-container {
  border-width: 4px;
  border-color: #dddddd;
}
