@import '../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-deck-preview-modal-content-container {
    width: 420px;
    .card-deck-preview-modal {
        padding: 30px 40px;
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: column;

        .card-deck-heading-container {
            padding-bottom: 1rem;
            display: flex;
            flex-direction: column;

            .card-deck-heading-close-icon-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                
                .card-deck-heading-info-incon {
                    margin-right: 20px;
                }

                .card-deck-modal-close-icon {
                    padding: 5px;
                    border: 1px solid #ffffff00;
                    border-radius: 50%;
                    background-color: #efefef;
                    cursor: pointer;
                    width: 35px;
                    height: auto;
                }
            }

            .card-deck-sub-heading-container {
                display: flex;

                .card-deck-heading-color-div {
                    height: 28px;
                    width: 14px;
                    background-color: #b1e5fc;
                    margin-right: 8px;
                    border-radius: 3.4px;
                }
    
                .card-deck-heading-text {
                    font-size: 18px;
                    font-weight: 600;
                    color: #1a1d1f;
                }
            }
        }

        .card-deck-content-container {
            border-radius: 10px;
            margin-top: 30px;
            overflow: hidden;
            flex: 1 1 auto;
            box-shadow: 0 4px 22px rgb(0 0 0 / 9%);
            margin-bottom: 40px;
        }
    }
}