@import '../../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* // .training-coming-soon-container {
//   height: inherit;
//   justify-content: center;
//   align-items: center;
//   display: flex;
// } */

.training-tab-container::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}

.training-tab-container::-webkit-scrollbar-thumb:vertical {
  background: #eee;
  border-radius: 10px;
}

.training-title-wrapper {
  display: flex;
  align-items: center;
}

.training-invite-user-container {
  display: none;

  @include screen(mobile) {
    display: block;
    margin-bottom: 17px;
  }
}

.training-title-container {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
  flex: 1 1 auto;
  margin-top: 7px;
  overflow: hidden;

  .training-path-title{
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @include screen(small-desktop) {
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.96px;
  }

  @include screen(small-desktop) {
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.96px;
  }

  @include screen(mobile) {
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
  }

  .training-title-breadcrumb {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.15px;
    text-align: left;
    color: #6f767e;
    margin-bottom: 20px;
    margin-left: 1px;
    margin-top: 10px;

    &:hover {
      color: #1a1d1f;
    }
  }
}

.training-container {
  background-color: #ffffff;
  padding-inline: 34px;
  padding-block: 42px;
  border-radius: 12px;
  flex: 1 1 auto;

  @include screen(tablet) {
    padding: 25px 29px;
  }

  @include screen(mobile) {
    padding: 23px 16px;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }
}

.setting-header-tablet-mobile-container {
  @include screen(tablet) {
    // display: flex;
    // flex-direction: row-reverse;
  }

  @include screen(mobile) {
    display: block;
  }

  .title-container {
    @include screen(tablet) {
      flex: 1 1 auto;
    }
  }
}

.training-nav-dropdown {
  width: 100%;
  margin-bottom: 26px;

  @include screen(mobile) {
    width: 100%;
    margin-bottom: 26px;
  }

  .chakra-divider {
    display: none;
  }
}

.training-header {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 30;
}

.training-header-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.training-header-text-container {
  flex-direction: column;
  margin-left: 15px;
}

.training-header-text {
  font-weight: 600;
  font-size: 20px;
}

.training-search-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.training-header-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.training-deck-container {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-height: 550px;
}

.training-deck {
  margin: 16.5px;
  max-width: 300px;

  flex-direction: column;
}

.training-deck-number-of-cards {
  position: absolute;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  width: 50%;
  height: 180px;
  right: 0px;
  top: 0px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0px 12px 12px 0px;
  // position: absolute;
  // top: 70px;
  // right: 20px;
  // width: 40%;
  // height: 60%;
  // align-self: flex-end;
  // justify-content: center;
  // align-items: center;
  // background-color: rgba(0,0,0,0.5);
  // border-radius: 12px;
}

.training-deck-number {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 20px;
}

.training-deck-name {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 180px;
  max-width: 200px;
}

.training-deck-card-inner-container {
  background-color: #e4e4e4;
  height: 180px;
  width: 220px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: relative;
}

.title-with-search-container {
  display: flex;

  @include screen(mobile) {
    display: block;
  }

  // align-items: center;
}

.training-search {
  width: 435px !important;

  @include screen(mobile) {
    width: 100% !important;
  }
}
