.responsive-modal-header-text {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.responsive-modal-desc {
  font-size: 15px;

  text-align: center;
  margin-top: 5px;
  padding: 0px 30px;
}

.responsive-image-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;

  margin-bottom: 30px;
  background: #d3765c;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.responsive-modal-container .ant-modal-body {
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  padding: 0px !important;
  height: unset !important;
  display: flex;
  flex-direction: column;
}

.responsive-modal-container .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 62%);
  height: unset;
  /* min-width: 772px; */
}

.responsive-modal-container .ant-modal {
  max-width: calc(100vw - 30px);
  margin: 8px auto;
}

.new-responsive-modal-container .ant-modal-body {
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  padding: 0px !important;
  height: unset !important;
  display: flex;
  flex-direction: row;
}

.new-responsive-modal-container .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  padding: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 62%);
  height: unset;
  /* min-width: 772px; */
}

.new-responsive-modal-container .ant-modal {
  max-width: calc(100vw - 30px);
  margin: 8px auto;
}

.new-responsive-image-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;

  /* margin-bottom: 30px; */
  /* background: #b1e5fc; */
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.app-modal-main-div {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.app-modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

.app-modal-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-modal-mobile-store-img {
  width: 137.5px;
  height: auto;
  cursor: pointer;
}

.app-modal-mobile-img-div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-modal-mobile-img {
  content: url(../../assets/images/delightree-mobile.png);
  width: 77%;
  height: auto;
}

.app-modal-content {
  width: 50%;
  background-color: #b1e5fc;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-details {
  width: 80%;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.14px;
}

.app-heading {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.36;
}

.app-store-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .app-details {
    width: 85%;
    text-align: center;
    margin-top: 12px;
    font-size: 13px;
    line-height: 1.43;
    letter-spacing: -0.14px;
  } 

  .app-heading {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.36;
  }

  .app-modal-mobile-store-img {
    width: 100px;
    height: auto;
  }

  .app-store-img-div {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media (max-width: 617px) {
  .app-modal-main-div {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    background-color: #b1e5fc;
    border-radius: 0.375rem;
  }

  .app-modal-content {
    width: 100%;
    background-color: white;
    border-bottom-right-radius: 0.375rem;
  }

  .app-modal-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .app-modal-mobile-img {
    content: url(../../assets/images/delightree-mobile-blue.png);
    width: 75%;
    height: auto;
  }

  .app-modal-mobile-img-div {
    width: 100%;
    padding-top: 20px;
    background-color: #b1e5fc;
    border-radius: 0.375rem;
  }
}