.sop-content-header-text {
  margin-left: 35px;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  color: #000;
  flex: 1 1 auto;
}

.sop-content-category-container {
  margin-left: 63px;
  margin-top: 15px;
  font-size: 15px;
}

.sop-content-category-text {
  color: #6c63ff;
  font-weight: 700;
}

.sop-content-doc-container {
  font-size: 16px;
  margin-left: 62px;
  margin-top: 32px;
  display: flex;
}

@media screen {
  @media (max-width: 768px) {
    .sop-content-header-text {
      margin-left: 0 !important;
    }

    .sop-content-category-container {
      margin-left: 0 !important;
    }
    .sop-content-doc-container {
      margin-left: 0 !important;
    }
  }
}

.ProseMirror {
  padding-bottom: 0px !important;
}

.ProseMirror > p:last-child {
  margin-bottom: 0px !important;
}

.ProseMirror > table {
  min-width: 100%;
}

table::-webkit-scrollbar {
  width: 4px;
}

/* Track */
table::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
table::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

/* Handle on hover */
table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* #document {
  display: block;
}

iframe {
  margin: 0px;
  padding: 0px;
  border: none;
  overflow: hidden;
} */
