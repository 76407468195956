@import '../../../mixins.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$mobile-max-width: 767px;
$tablet-max-width: 959px;
$small-desktop-max-width: 1199px;
$desktop-max-width: 1535px;
$big-screen-max-width: 2560px;
@mixin screen($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile-max-width + 1) and (max-width: $tablet-max-width) {
      @content;
    }
  } @else if $media == small-desktop {
    @media only screen and (min-width: $tablet-max-width + 1) and (max-width: $small-desktop-max-width) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $small-desktop-max-width + 1) and (max-width: $desktop-max-width) {
      @content;
    }
  } @else if $media == big-screen {
    @media only screen and (min-width: $desktop-max-width + 1) {
      @content;
    }
  }
}

@mixin flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

@mixin center-flex($type: '') {
  @if $type == important {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.updated-deck-card-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 62vh;
    overflow: auto;
    margin-top: 43px;

    .updated-deck-card-container-sub-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .updated-deck-img-cover {
            position: relative;
            width: 66px;
            height: 63px;
            border-radius: 8px;
            margin-right: 12px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }

            .updated-deck-isDeck{
                position: absolute;
                top: 0;
                right: 0;
                width: 48%;
                height: 100%;
                background: rgba(0, 0, 0, 0.8);

                display: flex;
                flex-direction: column;
                color: white;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                //border-radius: 0 8px 8px 0;

                svg{
                    width: 18px;
                }
            }
        }

        .updated-deck-card-info-cover {
            width: 600px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .updated-deck-card-title {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.01em;
                color: #1a1d1f;
            }

            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.01em;
                color: #9a9fa5;
            }
        }

        .updated-deck-close-icon-cover {
            position: absolute;
            right: 0;
            cursor: pointer;
        }
    }

}

.updated-deck-drag-icon {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.updated-deck-card-text-elips {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
}

.updated-deck-card-text-elips-preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}